import React from 'react';
import styled from 'styled-components';

import { Section, Container, SectionHeading } from '@components/global';
import BigPattern from '@components/BigPattern';

import { ReactComponent as IlluNarbe } from '@images/therapy/illu-narbe.svg';
import { ReactComponent as IlluAbnuetzung } from '@images/therapy/illu-abnuetzung.svg';
import { ReactComponent as IlluWirbelsaeule } from '@images/therapy/illu-wirbelsaeule.svg';
import { ReactComponent as IlluMuskelverspannung } from '@images/therapy/illu-muskelverspannung.svg';
import { ReactComponent as IlluHaltungsschwaeche } from '@images/therapy/illu-haltungsschwaeche.svg';
import { ReactComponent as IlluGelenksbeschwerden } from '@images/therapy/illu-gelenksbeschwerden.svg';
import { ReactComponent as IlluBeckenbodenbeschwerden } from '@images/therapy/illu-beckenbodenbeschwerden.svg';
import { ReactComponent as IlluSpannungskopfschmerzen } from '@images/therapy/illu-spannungskopfschmerzen.svg';
import { ReactComponent as IlluSportsehnenverletzungen } from '@images/therapy/illu-sportsehnenverletzungen.svg';
import { ReactComponent as DotGrid } from '@images/procedure/dot-grid.svg';

const ILLUSTRATIONS = [
  {
    image: IlluWirbelsaeule,
    text: 'Wirbelsäulen- und Bandscheibenbeschwerden',
  },
  {
    image: IlluAbnuetzung,
    text: 'Abnützungserscheinungen',
  },
  {
    image: IlluGelenksbeschwerden,
    text: 'Akute Gelenksbeschwerden',
  },
  {
    image: IlluSpannungskopfschmerzen,
    text: 'Spannungskopfschmerzen',
  },
  {
    image: IlluHaltungsschwaeche,
    text: 'Haltungsschwächen /-schäden, Fehlstellungen',
  },
  {
    image: IlluMuskelverspannung,
    text: 'Muskelverspannungen, Muskelzerrungen',
  },
  {
    image: IlluSportsehnenverletzungen,
    text: 'Sport- Bänder- u. Sehnenverletzungen',
  },
  {
    image: IlluBeckenbodenbeschwerden,
    text: 'Beckenbodenprobleme',
  },
  {
    image: IlluNarbe,
    text: 'Narben',
  },
];

const Therapy = () => (
  <StyledSection id="therapieangebot">
    <StyledContainer>
      <SectionHeading>Therapieangebot</SectionHeading>
      <BigPattern />
      <TherapyGrid>
        {ILLUSTRATIONS.map(({ image, text }) => (
          <TherapyItem>
            <dd>{image()}</dd>
            <dt>{text}</dt>
          </TherapyItem>
        ))}
      </TherapyGrid>
    </StyledContainer>
    <BottomGrid>{DotGrid()}</BottomGrid>
  </StyledSection>
);

const StyledSection = styled(Section)`
  position: relative;
`;

const TherapyGrid = styled.dl`
  margin-left: auto;
  max-width: 50rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 36px;
  justify-items: start;

  @media screen and (max-width: ${props => props.theme.screen.md}) {
    max-width: 26rem;
  }

  @media screen and (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

const TherapyItem = styled.div`
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr 1.75fr;
  align-items: center;
  position: relative;
  z-index: 10;

  @media screen and (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media screen and (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 2fr;
    justify-items: start;
    align-items: center;
    grid-gap: 20px;
  }

  dd {
    width: 100%;
    margin-left: 0;

    svg {
      width: 100%;
      max-width: 100%;
    }
  }

  dt {
    font-size: 14px;
    font-weight: 300;
    overflow-wrap: break-word;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const BottomGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: -200px;
  right: 2rem;
  z-index: 10;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    bottom: -100px;
    right: -50px;
  }
`;

export default Therapy;
