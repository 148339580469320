import React from 'react';
import styled from 'styled-components';

import { Section, Container, SectionHeading } from '@components/global';

import { ReactComponent as FormIcon } from '@images/procedure/form.svg';
import { ReactComponent as CalendarIcon } from '@images/procedure/calender.svg';
import { ReactComponent as EuroIcon } from '@images/procedure/euro.svg';
import { ReactComponent as TowelIcon } from '@images/procedure/towel.svg';
import { ReactComponent as DotGrid } from '@images/procedure/dot-grid.svg';

const STEPS = [
  {
    count: 1,
    title: 'Verordnungsschein holen',
    text:
      'Um Sie als Wahltherapeutin behandeln zu können, benötige ich einen Verordnungsschein mit Diagnose, welchen Sie von Ihrem Hausarzt oder Facharzt erhalten.',
    image: FormIcon,
  },
  {
    count: 2,
    title: 'Termin vereinbaren',
    text: 'Vereinbaren Sie einen Termin mit mir persönlich unter 066475089669.',
    image: CalendarIcon,
  },
  {
    count: 3,
    title: 'Nehmen Sie das mit zur Behandlung',
    text:
      'Bringen Sie bitte zum ersten Behandlungstermin den Verordnungsschein, wenn vorhanden Befunde und ein Badetuch mit.',
    image: TowelIcon,
  },
  {
    count: 4,
    title: 'Abrechnung',
    text:
      'Am Ende Ihrer Serie bekommen Sie eine Rechnung von mir, sobald Sie diese bezahlt haben, können Sie eine Rückerstattung eines gewissen Teiles bei Ihrem Versicherungsträger beantragen. Die Höhe der Rückerstattung ist von der jeweiligen Krankenkasse abhängig.',
    image: EuroIcon,
  },
];

const Procedure = () => (
  <StyledSection id="therapieablauf" accent>
    <StyledContainer>
      <SectionHeading>Therapieablauf</SectionHeading>
      <Grid>
        {STEPS.map(({ count, title, text, image }) => (
          <Item>
            <Icon>{image()}</Icon>
            <Text>
              <h3>{title}</h3>
              <p>{text}</p>
              <Count>{count}</Count>
            </Text>
          </Item>
        ))}
      </Grid>
    </StyledContainer>
    <TopGrid>{DotGrid()}</TopGrid>
    <BottomGrid>{DotGrid()}</BottomGrid>
  </StyledSection>
);

const StyledSection = styled(Section)`
  position: relative;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const Grid = styled.div`
  margin-top: 80px;
  display: grid;
  max-width: 700px;
  grid-gap: 80px;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 30;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 80px;
  position: relative;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-gap: 40px;
  }
`;

const Icon = styled.div`
  svg {
    max-width: 100%;
  }
`;

const Text = styled.div`
  position: relative;

  h3 {
    font-weight: bold;
    font-size: 24px;
    color: ${props => props.theme.color.primary};
  }

  p {
    font-size: 16px;
    font-weight: 300;

    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 14px;
    }
  }

  h3,
  p {
    position: relative;
    z-index: 20;
  }
`;

const Count = styled.span`
  font-size: 152px;
  font-weight: 800;
  color: ${props => props.theme.color.primary};
  opacity: 0.2;
  position: absolute;
  z-index: 10;
  top: -3rem;
  left: -3rem;
`;

const TopGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  top: -200px;
  right: 2rem;
  z-index: 10;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    top: -100px;
    right: -50px;
  }
`;

const BottomGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: -200px;
  left: 2rem;
  z-index: 10;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    bottom: -100px;
    left: -50px;
  }
`;

export default Procedure;
