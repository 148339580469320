import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { ReactComponent as DotGrid } from '@images/procedure/dot-grid.svg';
import Img from 'gatsby-image';

import { Section, Container, SectionHeading } from '@components/global';

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      copy: markdownRemark(fileAbsolutePath: { regex: "/about/" }) {
        id
        html
      }
      madl: file(
        sourceInstanceName: { eq: "about" }
        name: { eq: "eva-about" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <StyledSection id="uber-mich">
      <Container>
        <SectionHeading>Über Mich</SectionHeading>
        <Grid>
          <Art>
            <Img fluid={data.madl.childImageSharp.fluid} />
          </Art>
          <AboutCopy
            dangerouslySetInnerHTML={{ __html: data.copy.html }}
          ></AboutCopy>
        </Grid>
      </Container>
      <TopGrid>{DotGrid()}</TopGrid>
      <BottomGrid>{DotGrid()}</BottomGrid>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 80px;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    grid-gap: 20px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const AboutCopy = styled.div`
  text-align: left;
  color: ${props => props.theme.color.secondary};
  background: ${props => props.theme.color.tealLight};
  padding: 2rem;
  border-radius: 20px;

  h3 {
    font-size: 22px;
    font-weight: 700;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.color.secondary};
    margin-bottom: 1.8rem;
  }

  ul {
    padding-left: 1rem;
  }

  li {
    font-weight: 300;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
`;

const TopGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  top: -200px;
  left: 2rem;
  z-index: 10;
  opacity: 0.4;
  justify-items: start;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    top: -100px;
    left: -50px;
  }
`;

const BottomGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: -200px;
  right: 2rem;
  z-index: 10;
  opacity: 0.4;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    bottom: -100px;
    right: -50px;
  }
`;

const Art = styled.figure`
  max-width: 100%;
  width: 100%;
  margin: 0;
  transform: translateY(-10rem);

  img {
    border-radius: 20px;
  }

  @media (max-width: ${props => props.theme.screen.lg}) {
    transform: translateY(-16rem);
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    transform: none;
  }
`;

export default About;
