import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import Gallery from '@browniebroke/gatsby-image-gallery';
import '@browniebroke/gatsby-image-gallery/dist/style.css';

const Praxis = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { sourceInstanceName: { eq: "praxis" } }) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const images = data.images.edges.map(({ node }) => node.childImageSharp);

  return (
    <div>
      <Gallery images={images} />
    </div>
  );
};

export default Praxis;
