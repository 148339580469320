import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Pattern } from '@images/therapy/bigpattern.svg';

const BigPattern = () => <StyledDiv>{Pattern()}</StyledDiv>;

const StyledDiv = styled.div`
  width: 37%;
  position: absolute;
  top: 12rem;
  left: -3rem;

  @media screen and (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }

  svg {
    max-width: 100%;
  }
`;
export default BigPattern;
