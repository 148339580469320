import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import Therapy from '@sections/Therapy';
import Procedure from '@sections/Procedure';
import Contact from '@sections/Contact';
import Map from '@sections/Map';
import About from '@sections/About';
import Praxis from '@sections/Praxis';
import Footer from '@sections/Footer';

const IndexPage = () => {

    const mapsApiKey = "AIzaSyAUpWbIHKj-I0o4iGnQXZgPRdISHLD8-6Q"
    const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&v=3.exp&libraries=geometry,drawing,places`
    return (
        <Layout>
            <Navbar />
            <Header />
            <Therapy />
            <Procedure />
            <Contact />
            <Map
                isMarkerShown
                googleMapURL={googleMapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
            <About />
            <Praxis />
            <Footer />
        </Layout>
    );
};

export default IndexPage;
