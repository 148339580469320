import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        evamaria: file(
          sourceInstanceName: { eq: "hero" }
          name: { eq: "eva" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Anchor id="hero" />
          <Grid>
            <Text>
              <h1>
                Mit voller Motivation & Erfahrung, arbeiten wir gemeinsam an
                einer Lösung
              </h1>
              <br />
              <p>
                Die Physiotherapie bezeichnet sowohl mehrere verschiedene
                aktive, selbständig ausgeführte als auch passive Therapieformen.
                Physiotherapie konzentriert sich auf die Bewegungsfähigkeit und
                all diese beeinflussenden Systeme, sowie auf das Zusammenspiel
                von Sensorik und Motorik.
              </p>
            </Text>
            <Art>
              <Img fluid={data.evamaria.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  padding-top: 100px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-top: 100px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  h1 {
    font-weight: 700;
    color: ${props => props.theme.color.primary};

    @media (max-width: ${props => props.theme.screen.sm}) {
      font-size: 32px;
    }
  }

  p {
    font-weight: 300;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 30px;

    & > ${Text} {
      order: 2;
    }

    & > ${Art} {
      order: 1;
    }
  }
`;

const Anchor = styled.div`
  transform: translateY(-6rem);
`;
export default Header;
