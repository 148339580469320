import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const position = { lat: 48.50238647158622, lng: 13.568694936178066 };

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={18} defaultCenter={position}>
      {props.isMarkerShown && (
        <Marker
          position={position}
          icon={{
            url: '/icons/Marker.svg',
            scaledSize: new window.google.maps.Size(64, 64),
          }}
        />
      )}
    </GoogleMap>
  ))
);

export default Map;
