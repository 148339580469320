import React from 'react';
import { Section, Container, SectionHeading } from '@components/global';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { ReactComponent as PhoneIcon } from '@static/icons/telephone.svg';
import { ReactComponent as MailIcon } from '@static/icons/envelope.svg';
import { ReactComponent as DotGrid } from '@images/procedure/dot-grid.svg';

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        madl: file(
          sourceInstanceName: { eq: "contact" }
          name: { eq: "praxis" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <StyledSection id="termine-and-kontakt">
        <Container>
          <SectionHeading>Termine & Kontakt</SectionHeading>
          <Grid>
            <ContactInfo>
              <h2>
                <span>Physiotherapie</span> <br /> Eva-Maria Schneglberger
              </h2>
              <ContactCopy>
                Um einen passenden Termin bei mir zu vereinbaren, melden Sie
                sich bitte unter:
              </ContactCopy>
              <ContactLink href="tel:+4366475089669">
                <div className="icon">{PhoneIcon()}</div>
                0664 750 89669
              </ContactLink>
              <ContactLink href="mailto:praxis@physio-evamaria.at">
                <div className="icon">{MailIcon()}</div>
                praxis@physio-evamaria.at
              </ContactLink>
              <Address>
                <h4>Adresse</h4>
                <p>
                  Freundorf 4/3 <br />
                  A-4792 Münzkirchen
                </p>
              </Address>
            </ContactInfo>
            <Art>
              <Img fluid={data.madl.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
        <TopGrid>{DotGrid()}</TopGrid>
        <BottomGrid>{DotGrid()}</BottomGrid>
      </StyledSection>
    )}
  />
);

const StyledSection = styled(Section)`
  position: relative;
`;

const Grid = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  position: relative;
  z-index: 30;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: ${props => props.theme.color.secondary};
    margin-bottom: 1.5rem;

    span {
      font-size: 40px;
      opacity: 0.25;

      @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 28px;
      }
    }
  }
`;

const ContactCopy = styled.p`
  font-weight: 300;
  margin-bottom: 1rem;
  font-size: 16px;
  color: ${props => props.theme.color.secondary};
`;

const ContactInfo = styled.div`
  background: ${props => props.theme.color.tealLight};
  padding: 3rem 2rem;
  border-radius: 20px;
`;

const ContactLink = styled.a`
  padding: 1rem;
  border-radius: 5px;
  background: #b6ecec;
  box-shadow: 0 2px 4px hsla(180, 43%, 76%, 0.6);
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  color: ${props => props.theme.color.secondary};
  font-weight: 300;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    background: #aae4e4;
    box-shadow: 0 6px 12px hsla(180, 43%, 76%, 0.6);
    transform: translateY(-2px);
  }

  .icon {
    height: 26px;
    width: 26px;
    margin-right: 1rem;
    opacity: 0.5;
  }
`;

const Address = styled.div`
  color: ${props => props.theme.color.secondary};

  h4 {
    margin-bottom: 0;
    margin-top: 3rem;
  }

  p {
    color: ${props => props.theme.color.secondary};
    font-weight: 300;
  }
`;

const TopGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  top: -200px;
  left: 2rem;
  z-index: 10;
  opacity: 0.4;
  justify-items: start;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    top: -100px;
    left: -50px;
  }
`;

const BottomGrid = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: -200px;
  right: 2rem;
  z-index: 10;
  opacity: 0.4;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
    height: 200px;
    bottom: -100px;
    right: -50px;
  }
`;

const Art = styled.figure`
  max-width: 100%;
  margin: 0;
  transform: translateY(8rem);

  img {
    border-radius: 20px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    transform: none;
  }
`;

export default Contact;
